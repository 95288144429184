<template>
  <div class="exam-paper">
    <header class="exam-header">
      <div class="container">
        <h5>{{ title }}</h5>
        <ul class="tip">
            <li class="tip-item">总分：102220</li>
            <li class="tip-item">及格线：60</li>
            <li class="tip-item">切屏次数： 无限次</li>
            <li class="tip-item">总时长： 30分钟</li>
            <li class="tip-item">若有主观题，系统不支持自动判分</li>
        </ul>
      </div>
    </header>
  <div class="container">
    <section class="container flex main">
      <div class="testPaper">
        <div class="alone" v-if="radioArr.length>0">
          <div class="headline">
            <span class="classify">一、单选题 </span>
            <span class="score">（ 本大题共{{radioArr.length}}小题，总分{{radioArr.length*radioArr[0].score}}分 ）</span> 
          </div>
          <div class="item" v-for="(item,index) in radioArr" :key="index">
              <div class="questions-item-head">
                <div class="type-title">{{index+1}}、单选题<span>({{item.score}}分)</span></div>
                <div class="mark" :class="{'ismark':item.isMarked}" @click="mard(index,1)">标记</div>
              </div>
              <div class="title">{{item.title}}</div>
              <div class="opt-item"  v-for="(i,index1) in item.options" :class="{'active':i.isRight}" :key="index1" @click="singleChoose(index,index1)">
                <span class="letter">{{handleLetter(index1)}}</span>
                <span class="text">{{ i.title }}</span>
              </div>
          </div>
        </div>
        <div class="alone" v-if="multipleArr.length>0">
          <div class="headline">
            <span class="classify">二、多选题 </span>
            <span class="score">（ 本大题共{{multipleArr.length}}小题，总分{{multipleArr.length*multipleArr[0].score}}分 ）</span> 
          </div>
          <div class="item" v-for="(item,index) in multipleArr" :key="index">
            <div class="questions-item-head">
                <div class="type-title">{{index+1}}、多选题 <span>({{item.score}}分)</span></div>
                <div class="mark"  :class="{'ismark':item.isMarked}" @click="mard(index,2)">标记</div>
              </div>
            <div class="title">{{item.title}}</div>
            <div class="opt-item" v-for="(i,index1) in item.options" :key="index1"  :class="{'active':i.isRight}" @click="multipleChoose(index,index1)">
              <span class="letter">{{handleLetter(index1)}}</span>
              <span class="text">{{ i.title }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="examInfo">
        <div class="time">
          剩余时间
          <div class="down">00:{{minute}}:{{Second}}</div>
        </div>
        <div class="sheet">
          <div class="title">答题卡</div>
          <div class="progress">
            <div class="left">
              <el-progress :percentage="30" :show-text="false"></el-progress>
            </div>
            <span>
              {{ chooseNum }}/{{radioArr.length  + multipleArr.length}} 
            </span>
          </div>
          <div class="label">
            <div class="item">
              <span></span>
              已答
            </div>
            <div class="item">
              <span></span>
              未答
            </div>
            <div class="item">
              <span></span>
              标记
            </div>
          </div>
          <div class="questionBox">
            <div class="title">一、单选题</div>
            <div class="box">
              <template v-for="(item,index) in radioArr">
                <div class="item" :class="{'isanswers':item.answers.length>0,'mark':item.isMarked}"  :key="index" v-if="item.questionType == 1">
                  {{ index + 1 }}
                </div>
              </template>
            </div>
            <div class="title">二、多选题</div>
            <div class="box">
              <template v-for="(item,index) in multipleArr">
                <div class="item" :class="{'isanswers':item.answers.length,'mark':item.isMarked}"  :key="index" v-if="item.questionType == 2">
                  {{ index + 1 }}
                </div>
              </template>
            </div>
          </div>
          <div class="btnbox flex">
            <el-button type="warning" class="btn">暂存</el-button>
            <el-button type="primary" class="btn">交卷</el-button>
          </div>
        </div>
      </div>
    </section>
  </div>
</div>
</template>
<script>

export default {
  data () {
    return {
      title:'每日一练(体验)(2023-06-19)',
      timeStamp:1800,
      minute:'00',
      Second:'00',
      answerNumber:0,
      radioArr:[
      {
              "questionId":273489,
              "title":"水污染的主要来源不包括",
              "titimgs":null,
              "score":1,
              "answers":[

              ],
              "analysis":"",
              "analysisimgs":null,
              "questionType":1,
              "optionRandom":1,
              "questionAnswers":"",
              "myScore":0,
              "strAnswer":"",
              "isMarked":false,
              "answerImg":null,
              "options":[
                  {
                      "title":"工业污染源",
                      "img":"",
                      "id":0,
                      "isRight":false
                  },
                  {
                      "title":"农业污染源",
                      "img":"",
                      "id":1,
                      "isRight":false
                  },
                  {
                      "title":"大气污染源",
                      "img":"",
                      "id":2,
                      "isRight":false
                  },
                  {
                      "title":"生活污染源",
                      "img":"",
                      "id":3,
                      "isRight":false
                  }
              ],
              "list":null
          },
          {
              "questionId":273478,
              "title":"先秦时代，教育内容以“六艺”为主，下列不属于“六艺”的是（ ）。",
              "titimgs":null,
              "score":1,
              "answers":[

              ],
              "analysis":"",
              "analysisimgs":null,
              "questionType":1,
              "optionRandom":2,
              "questionAnswers":"",
              "myScore":0,
              "strAnswer":"",
              "isMarked":false,
              "answerImg":null,
              "options":[
                  {
                      "title":"射",
                      "img":"",
                      "id":0,
                      "isRight":false
                  },
                  {
                      "title":"御",
                      "img":"",
                      "id":1,
                      "isRight":false
                  },
                  {
                      "title":"礼",
                      "img":"",
                      "id":2,
                      "isRight":false
                  },
                  {
                      "title":"武",
                      "img":"",
                      "id":3,
                      "isRight":false
                  }
              ],
              "list":null
          },
      ],
      multipleArr:[
        {
              "questionId":273482,
              "title":"以下哪种水果核不可以食用",
              "titimgs":null,
              "score":1,
              "answers":[

              ],
              "analysis":"",
              "analysisimgs":null,
              "questionType":2,
              "optionRandom":1,
              "questionAnswers":"",
              "myScore":0,
              "strAnswer":"",
              "isMarked":false,
              "answerImg":null,
              "options":[
                  {
                      "title":"苹果核",
                      "img":"",
                      "id":0,
                      "isRight":false
                  },
                  {
                      "title":"枇杷核",
                      "img":"",
                      "id":1,
                      "isRight":false
                  },
                  {
                      "title":"苦杏仁核",
                      "img":"",
                      "id":2,
                      "isRight":false
                  },
                  {
                      "title":"桃核",
                      "img":"",
                      "id":3,
                      "isRight":false
                  }
              ],
              "list":null
          }
      ],
      examList:{
        type:'single',
        Score:80,
        
      }
    };
  },
  created(){
    this.resetTime(this.timeStamp)

  },
  computed:{
    
    handleLetter:function(){
      return function(index){
        let arr = ['A','B','C','D']
        return arr[index]
      }
    },
    chooseNum:function(){
      let Number = 0
      // Number = 
      return 1
    },
    // chooseQuestion:function(){
    //   let Number = 0
    //   this.examList.questions.forEach((item)=>{
    //     if(item.answers.length){
    //       Number++
    //     }
    //   })
    //   this.answerNumber = Number
    //   return 1
    // }
  },
  methods:{
      mard(index,type){
        if(type == 1){
          this.radioArr[index].isMarked = !this.radioArr[index].isMarked
        }
        if(type == 2){
          this.multipleArr[index].isMarked = !this.multipleArr[index].isMarked
        }
      },
    			//倒计时start     需要传入的参数为秒数，此方法倒计时结束后会自动刷新页面
			resetTime(timeStamp){
			    var timer=null;
			    var t=timeStamp;
			    var m=0;
			    var s=0;
			    m=Math.floor(t/60%60);
			    m<10&&(m='0'+m);
			    s=Math.floor(t%60);	
          

		        //开启定时任务
		        timer=setInterval(countDown,1000);
          const that = this
			    function countDown(){
			        s--;
			        s<10&&(s='0'+s);
			        if(s.length>=3){
			            s=59;
			            m=(Number(m)-1);
			            m<10&&(m='0'+m);
			        }
			        if(m.length>=3){
			            m='00';
			            s='00';
			            //倒计时结束,关闭定时任务。
			            clearInterval(timer);
			        }			     
			        // $("#Pk10Time").html(m+"分"+s+"秒");
              that.minute = m
              that.Second = s
			        if(m==0 && s==0){
			            //倒计时结束
			          that.$router.push({path:'/exam'})
			        }
			    }			  
			},
      singleChoose(index,index1){
          this.radioArr[index].options. forEach(element => {
            element.isRight = false
          });
          this.radioArr[index].answers = []
          this.radioArr[index].options[index1].isRight = true
          const newanswers = []
          newanswers.push(this.radioArr[index].options[index1].id)
          this.$set( this.radioArr[index], 'answers', newanswers)
      },
      multipleChoose(index,index1){
          this.multipleArr[index].options[index1].isRight = !this.multipleArr[index].options[index1].isRight
          let arr = []
          this.multipleArr[index].options.forEach((item)=>{
            if(item.isRight){
              arr.push(item.id)
            }
          })
          this.multipleArr[index].answers  = arr
      },
  }
}
</script>
<style lang='scss' scoped>
.exam-paper{
  background: #eff1f4;
}
.exam-header{
  text-align: center;
  height: 140px;
  background: #fff;
  h5{
    padding-top: 40px;
      font-size: 30px;
      font-weight: 700;
      margin-bottom: 10px;
    }
  .tip{
    display: flex;
    justify-content: center;
    .tip-item{
      border-right: 1px solid #666;
      margin-right: 10px;
      padding-right: 10px;
      &:last-child{
        border: none;
      }
    }
  }
}
.main{
  justify-content: space-between;
}
.testPaper{
  width: 894px;
  .headline{
    line-height: 68px;
    color: #202020;
    .classify{
      font-size: 20px;
    }
    .score{
      font-size: 16px;
    }
  }
  .item{
    padding: 24px 24px 8px 24px;
    background-color: #fff;
    margin-bottom: 16px;
    box-sizing: border-box;
    cursor: pointer;
    .questions-item-head{
      margin-bottom: 16px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-direction: row;
      .type-title{
        color: #e10808;
        font-size: 18px;
        line-height: 30px;
        span{
          color: #202020;
        }
        
      }
      .ismark{
          color: #e10808;
        }
    }
    .title{
      font-size: 18px;
      line-height: 1.7;
      color: #202020;
      margin: 16px 0;
    }
    .opt-item{
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-direction: row;
      font-size: 16px;
      margin-bottom: 16px;
      .letter{
        width: 28px;
        height: 28px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;
        margin-right: 20px;
        border: 1px solid #ddd;
      }
      .text{
        color: #202020;
        line-height: 28px;
        max-width: calc(100% - 48px);
      }
    }
    .active{
      color: #447dff;
      .letter{
        border: 1px solid #447dff;
      }
      .text{
        color: #447dff;
      }
    }
  }
 
}
.examInfo{
  width: 310px;
  margin-top: 20px;
  margin-left: 16px;
 .time{
    background-color: #fff;
    padding: 14px;
    margin-bottom: 16px;
    text-align: center;
    .down{
      font-size: 36px;
      color: #f65464;
      letter-spacing: 1px;
      font-weight: 700;
      line-height: 42px;
    }
  }
  .sheet{
    background-color: #fff;
    padding: 20px 16px;
    margin-top: 20px;
    box-sizing:border-box;
    .progress{
      padding: 20px 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-direction: row;
      .left{
        width: 200px;
      }
    }
    
  .label{
      display: flex;
      justify-content: space-around;
      .item{
        display: flex;
        align-items: center;
        span{
          display: inline-block ;
          width: 16px;
          height: 16px;
          margin-right: 4px;
          border-radius: 2px;
        }
        &:nth-child(1) span{
          background-color: #447dff;
        }
        &:nth-child(2) span{
          border: 1px solid #999;
        }
        &:nth-child(3) span{
          border: 1px solid #999;
          position: relative;
          overflow: hidden;
          &::after{
            content: "";
            position: absolute;
            bottom: -10px;
            right: -10px;
            width: 20px;
            height: 20px;
            background-color: #f65464;
            transform: rotate(45deg);
          }
        }
      }
      
    }
    .questionBox{
      margin-top: 20px;
      .title{
        margin: 14px 0;
      }
      .box{
        display: flex;
        flex-wrap: wrap;

        .item{
          float: left;
          width: 36px;
          height: 40px;
          overflow: hidden;
          border-radius: 2px;
          border: 1px solid #999;
          cursor: pointer;
          margin-right: 14px;
          margin-bottom: 14px;
          font-size: 18px;
          position: relative;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: row;
        }
        .isanswers{
          border: 1px solid #447dff;
          color: #447dff;
        }
        .mark{
          position: relative;
          overflow: hidden;
          &::after{
            content: "";
            position: absolute;
            bottom: -10px;
            right: -10px;
            width: 20px;
            height: 20px;
            background-color: #f65464;
            transform: rotate(45deg);
          }
        }
      }
    }
  }
.btnbox{
  margin-top: 16px;
  .btn{
    width: 120px;
    height: 40px;
    font-size: 16px;
  }
}
}
</style>